<template>

  <b-button
    v-b-modal="`modal-exportar-${modulo}`"
    variant="secondary"
    class="btn-sm btn-sm-block"
    :title="!disabledExport
      ? `Descargar ${modulo}`
      : `Marque los ${modulo} que desea descargar`"
    :disabled="disabledExport"
  >
    <feather-icon
      icon="DownloadIcon"
    />
    <b-modal
      :id="`modal-exportar-${modulo}`"
      centered
      size="lg"
      :title="`Exportar ${modulo}`"
      width="900px"
      hide-footer
    >
      <b-button
        class="mt-1"
        variant="primary"
        block
        size="sm"
        :title="`Exportar ${modulo}`"
        @click.prevent="!disabledExport
          ? processExport()
          : ''"
      >
        Exportar {{ modulo }}
      </b-button>

      <b-button
        class="mt-1"
        variant="primary"
        block
        size="sm"
        :title="`Exportar Precios Cantidad ${modulo}`"
        @click.prevent="!disabledExport
          ? processExportPreciosCantidad()
          : ''"
      >
        Exportar Precios Cantidad {{ modulo }}
      </b-button>
      <div
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          class="mt-1 mb-1 mr-1 btn-sm-block"
          variant="outline-secondary"
          @click.prevent="closeModal()"
        >
          Cerrar
        </b-button>
      </div>
    </b-modal>
  </b-button>
</template>

<script>
import {
  BButton, BModal,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BButton,
    BModal,
  },
  props: {
    modulo: {
      type: String,
      required: true,
    },
    disabledExport: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      idItems: [],
      urlModulo: null,
    }
  },
  methods: {
    setInfo() {
      this.urlModulo = this.modulo.toLowerCase()
      this.idItems = []
      this.items.forEach(item => {
        if (item.chkSelected) {
          this.idItems.push(item.id)
        }
      })
    },
    processExport() {
      this.setInfo()
      const url = `${axios.defaults.baseURL}/${this.urlModulo}/export/${this.idItems}`
      window.location.href = url
    },
    processExportPreciosCantidad() {
      this.setInfo()
      const urlProductos = `${axios.defaults.baseURL}/${this.urlModulo}/exportPreciosCantidad/${this.idItems}`
      window.location.href = urlProductos
    },
    closeModal() {
      this.idItems = []
      this.$root.$emit('bv::hide::modal', `modal-exportar-${this.modulo}`)
    },
  },
}
</script>
